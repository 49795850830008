export default [
    {
        title: 'Ana Sayfa',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Ürün',
        icon: 'PackageIcon',
        route: 'product',
    },
    {
        title: 'Müşteri',
        icon: 'UsersIcon',
        route: 'customer',
    },
    {
        title: 'Sipariş',
        icon: 'ShoppingBagIcon',
        children: [
            {
                title: 'Ekle',
                route: 'orderAdd',
            },
            {
                title: 'Liste',
                route: 'orderList',
            },
        ],
    },
    {
        title: 'Kullanıcılar',
        icon: 'UserPlusIcon',
        route: 'user',
        role: 'Admin'
    },
]
